import React, { useState } from 'react'
import { UserService } from '../../../services/UserService'

type FormField = {
  firstName: string
  lastName: string
  email: string
  requireUpdatePassword: boolean
  password?: string
  confirmedPassword?: string
}

type Props = {
  onCreated: (email: string, firstName: string, lastName: string) => void
  redirect?: { uri: string; clientId: string }
  withoutOptionalPassword: boolean
}

const containsNumberOrSpecial = new RegExp('(\\d|\\W)')
const containsLowerCase = new RegExp('(.*[a-z].*)')
const containsUpperCase = new RegExp('(.*[A-Z].*)')

export const CreateUserForm = ({ onCreated, redirect, withoutOptionalPassword = false }: Props) => {
  const [form, setFormData] = useState<FormField>({
    firstName: '',
    lastName: '',
    email: '',
    requireUpdatePassword: false
  })
  const [error, setError] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const setEmail = (email: string) => setFormData({ ...form, email })
  const setFirstName = (firstName: string) => setFormData({ ...form, firstName })
  const setLastName = (lastName: string) => setFormData({ ...form, lastName })
  const setRequireUpdatePassword = (requireUpdatePassword: boolean) => setFormData({ ...form, requireUpdatePassword })

  const withPassword = form.password === '' || !!form.password
  const setPassword = (password: string) => setFormData({ ...form, password })
  const setConfirmedPassword = (confirmedPassword: string) => setFormData({ ...form, confirmedPassword })

  const passwordToTest = form.password || ''
  const isValidPassord =
    containsLowerCase.test(passwordToTest) &&
    containsUpperCase.test(passwordToTest) &&
    containsNumberOrSpecial.test(passwordToTest) &&
    passwordToTest.length >= 10

  const isValid =
    form.email &&
    form.firstName &&
    form.lastName &&
    (withPassword ? form.password === form.confirmedPassword && isValidPassord : true)

  const createUser = (e: React.FormEvent) => {
    e.preventDefault()

    setError(undefined)
    setIsLoading(true)
    UserService.createUser(
      form.email,
      form.firstName,
      form.lastName,
      form.password,
      redirect,
      form.requireUpdatePassword
    )
      .then(() => {
        setIsLoading(false)
        onCreated(form.email, form.firstName, form.lastName)
      })
      .catch(error => {
        setError(error.message)
        setIsLoading(false)
      })
  }

  return (
    <div className="p-md flex flex-col items-start gap-md" style={{ width: '40vw' }}>
      <h1 className="text-xl">New User</h1>

      <div className="w-full">
        <label htmlFor="email" className="mb-xs inline-block">
          E-mail
        </label>
        <input
          id="email"
          type="email"
          autoComplete="off"
          required
          value={form.email || ''}
          onChange={({ target }) => setEmail(target.value)}
        />
      </div>

      <div className="w-full">
        <label htmlFor="firstName" className="mb-xs inline-block">
          First Name
        </label>
        <input
          id="firstName"
          type="text"
          autoComplete="off"
          required
          value={form.firstName || ''}
          onChange={({ target }) => setFirstName(target.value)}
        />
      </div>

      <div className="w-full">
        <label htmlFor="lastName" className="mb-xs inline-block">
          Last Name
        </label>
        <input
          id="lastName"
          type="text"
          autoComplete="off"
          required
          value={form.lastName || ''}
          onChange={({ target }) => setLastName(target.value)}
        />
      </div>

      {!withoutOptionalPassword ? (
        <div className="flex gap-lg">
          <label htmlFor="requireUpdatePassword" className="flex gap-sm items-center">
            <input
              id="requireUpdatePassword"
              type="checkbox"
              className="checkbox"
              checked={form.requireUpdatePassword}
              onChange={() => {
                setRequireUpdatePassword(!form.requireUpdatePassword)
              }}
            />
            Password Reset E-mail
          </label>

          <label htmlFor="withPassword" className="flex gap-sm items-center">
            <input
              id="withPassword"
              type="checkbox"
              className="checkbox"
              checked={withPassword}
              onChange={() => {
                if (withPassword) {
                  setFormData({ ...form, password: undefined, confirmedPassword: undefined })
                } else {
                  setFormData({ ...form, password: '', confirmedPassword: '' })
                }
              }}
            />
            With password?
          </label>
        </div>
      ) : null}

      {withPassword ? (
        <React.Fragment>
          <div>
            <h2 className="text-lg">Set password</h2>
            <p>Min length 10, upper and lowercase, one digit or special character.</p>
          </div>

          <div className="w-full">
            <label htmlFor="password" className="mb-xs inline-block">
              Password
            </label>
            <input
              id="password"
              required
              type="password"
              autoComplete="off"
              value={form.password || ''}
              onChange={({ target }) => setPassword(target.value)}
              disabled={!withPassword}
            />
            {form.password !== '' && !isValidPassord && (
              <div className="text-red mt-xs">
                At least one upper case, one lower case and one digit or special character. Min length 10.
              </div>
            )}
          </div>

          <div className="w-full">
            <label htmlFor="confirm" className="mb-xs inline-block">
              Confirm
            </label>
            <input
              id="confirm"
              required
              type="password"
              autoComplete="off"
              value={form.confirmedPassword || ''}
              onChange={({ target }) => setConfirmedPassword(target.value)}
              disabled={!withPassword}
            />
            {form.password !== '' && form.password !== form.confirmedPassword && (
              <div className="text-red mt-xs">Does not match password</div>
            )}
          </div>
        </React.Fragment>
      ) : null}

      {withPassword && <div>The user can login with the credentials set.</div>}

      {form.requireUpdatePassword && (
        <p>
          The user will receive an e-mail with a link to configure their password.
          {redirect ? ' Once password set, the user will be redirected to ' + redirect.uri : ''}
        </p>
      )}

      {error ? <p className="text-red">{error}</p> : null}

      <button className="btn btn-primary-dark self-end" onClick={createUser} disabled={!isValid}>
        {isLoading ? <div className="loadingSpinner" /> : 'Create User'}
      </button>
    </div>
  )
}
